.alert-main {
  -webkit-animation: fadeIn ease 0.5s;
          animation: fadeIn ease 0.5s;
  width: 500px;
  margin: auto;
  position: fixed;
  background-color: white;
  min-height: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
  top: 20vh;
  left: calc(50vw - 250px); }
  @media (max-width: 550px) {
    .alert-main {
      width: 90vw;
      left: 5vw; } }

.alert-header {
  font-size: 26px;
  font-weight: 600; }

.alert-body {
  text-align: center;
  font-size: 17px; }

.alert-button {
  width: 90px;
  height: 50px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: white;
  border-radius: 40px;
  font-size: 17px;
  font-weight: 600;
  border-radius: 30px;
  align-items: center;
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px; }

.backdrop {
  -webkit-animation: fadeIn ease 1s;
          animation: fadeIn ease 1s;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9998; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
